<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310.74 310.74">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <circle class="cls-2" cx="155.37" cy="155.37" r="151.87" />
          <polyline class="cls-2" points="47.98 47.98 47.98 47.98 47.98 47.98 145.3 145.59" />
          <polyline class="cls-2" points="262.76 262.75 262.76 262.76 262.76 262.76" />
          <line class="cls-2" x1="161.04" y1="161.23" x2="262.76" y2="262.75" />
        </g>
        <path
          class="cls-1"
          d="m97.79,210.73c3.8,4.31,10.53,12.83,10.53,17.26,0,5.8-4.72,10.53-10.53,10.53s-10.53-4.73-10.53-10.53c0-4.43,6.72-12.95,10.53-17.26m2.58-7.04c1.24-7.26,1.81-10.58,7.8-16.57l18.15-18.15h21.26l-28.78,28.78c-5.99,5.99-9.3,6.55-16.57,7.79-.71.12-1.46.25-2.24.39.14-.78.26-1.53.39-2.24m60.83-69.6l5.32,5.31,5.32,5.31h0s-20.75,20.75-20.75,20.75h-21.26l31.37-31.37h0Zm-5.52-10.49l12.51-12.5,26.64,26.64-12.5,12.5-6.76-6.76h0s-6.56-6.56-6.56-6.56l-13.32-13.32h0Zm50.55-38.63c3.93-3.93,10.79-3.93,14.72,0,4.06,4.06,4.06,10.66,0,14.72l-29.6,29.6-14.72-14.72,29.61-29.6h0Zm-108.43,157.05c7.74,0,14.04-6.3,14.04-14.04,0-5.69-6.96-14.45-10.64-18.69.52-.09,1.13-.21,1.62-.29,7.29-1.24,11.68-1.99,18.46-8.77l31.78-31.78,21.26-21.26,6.76,6.76c.34.34.79.51,1.24.51s.9-.17,1.24-.51l14.98-14.98c.69-.69.69-1.8,0-2.48l-4.72-4.72,29.6-29.6c5.42-5.42,5.42-14.25,0-19.68-5.26-5.26-14.43-5.26-19.68,0l-29.61,29.6-4.72-4.72c-.69-.69-1.8-.69-2.48,0l-14.99,14.98c-.33.33-.51.77-.51,1.24s.19.91.51,1.24l6.76,6.76-34.37,34.37-18.66,18.66c-6.78,6.78-7.53,11.17-8.78,18.46-.22,1.28-.47,2.7-.75,4.22-2.14,2.31-12.4,13.76-12.4,20.67,0,7.74,6.3,14.04,14.04,14.04"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #267891;
  stroke-width: 1.75px;
}

.cls-1,
.cls-2 {
  stroke: #267891;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-2 {
  fill: none;
  stroke-width: 6.99px;
}
</style>
